<template>
    <v-container>
        <table class="board-list">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:10%;" class="board-list__txt">NO.</th>
                    <th style="width:52.5%;" class="board-list__tit">제목</th>
                    <th style="width:12.5%;" class="board-list__txt">작성자</th>
                    <th style="width:12.5%;" class="board-list__txt">작성일</th>
                    <th style="width:12.5%;" class="board-list__txt">링크</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(board, index) in boards" :key="`board-${index}`" @click="openWindow(board.meta.href)" style="cursor:pointer;">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ summary.totalCount - skip - index }}
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            {{ board.subject }}
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.writer.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        <v-icon style="color: #222;">mdi-open-in-new</v-icon>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="board-bottom">
            <pagination-component :count="count" :value="page" @change="changePage" />
        </div>
    </v-container>
</template>

<script>
import paginationComponent from "@/components/client/control/pagination-component.vue";
import { mdiOpenInNew } from '@mdi/js';

export default {
    components: { paginationComponent },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    data() {
        return {
            mdiOpenInNew
        }
    },
    methods: {
        changePage(page) {
            this.page = page;
            this.skip = (page - 1) * this.limit;
            this.$emit("page", page);
        },
        openWindow(url) {
            window.open(url)
        }
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
    },
};
</script>
