var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('table', {
    staticClass: "board-list"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("NO.")]), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "52.5%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "12.5%"
    }
  }, [_vm._v("작성자")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "12.5%"
    }
  }, [_vm._v("작성일")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "12.5%"
    }
  }, [_vm._v("링크")])])]), _c('tbody', _vm._l(_vm.boards, function (board, index) {
    return _c('tr', {
      key: `board-${index}`,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.openWindow(board.meta.href);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.summary.totalCount - _vm.skip - index) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_vm._v(" " + _vm._s(board.subject) + " ")])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.writer.name) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.createdAt.toDate()) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('v-icon', {
      staticStyle: {
        "color": "#222"
      }
    }, [_vm._v("mdi-open-in-new")])], 1)]);
  }), 0)]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('pagination-component', {
    attrs: {
      "count": _vm.count,
      "value": _vm.page
    },
    on: {
      "change": _vm.changePage
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }